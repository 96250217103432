<template>
  <div>
    <el-row :gutter="20">
      <!-- <el-col :span="24"> -->
      <!-- <el-card shadow="hover" class="mgb20" style="height:252px;">
                    <div class="user-info">
                        <img src="../assets/img/img.jpg" class="user-avator" alt />
                        <div class="user-info-cont">
                            <div class="user-info-name">{{ name }}</div>
                            <div>{{ role }}</div>
                        </div>
                    </div>
                    <div class="user-info-list">
                        上次登录时间：
                        <span>2019-11-01</span>
                    </div>
                    <div class="user-info-list">
                        上次登录地点：
                        <span>东莞</span>
                    </div>
                </el-card> -->
      <!-- <el-card
          shadow="hover"
          style="height:252px;"
        >
          <template #header>
            <div class="clearfix">
              <span>语言详情</span>
            </div>
          </template>
          Vue
          <el-progress
            :percentage="71.3"
            color="#42b983"
          ></el-progress>JavaScript
          <el-progress
            :percentage="24.1"
            color="#f1e05a"
          ></el-progress>CSS
          <el-progress :percentage="13.7"></el-progress>HTML
          <el-progress
            :percentage="5.9"
            color="#f56c6c"
          ></el-progress>
        </el-card> -->
      <!-- <el-card
          shadow="hover"
          style="height:252px;"
        >
          <template #header>
            <div class="clearfix">
              <span>语言详情</span>
            </div>
          </template>
          Vue
          <el-progress
            :percentage="71.3"
            color="#42b983"
          ></el-progress>JavaScript
          <el-progress
            :percentage="24.1"
            color="#f1e05a"
          ></el-progress>CSS
          <el-progress :percentage="13.7"></el-progress>HTML
          <el-progress
            :percentage="5.9"
            color="#f56c6c"
          ></el-progress>
        </el-card>
      </el-col> -->
      <el-col :span="16">
        <el-row
          :gutter="20"
          class="mgb20"
        >
          <el-col :span="8">
            <el-card
              shadow="hover"
              :body-style="{ padding: '0px' }"
            >
              <div class="grid-content grid-con-1">
                <i class="el-icon-s-goods grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{dashboard.total_number}}</div>
                  <div>产品总数</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="hover"
              :body-style="{ padding: '0px' }"
            >
              <div class="grid-content grid-con-2">
                <i class="el-icon-s-goods grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{dashboard.tomonth_number}}</div>
                  <div>当月产品总数</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="hover"
              :body-style="{ padding: '0px' }"
            >
              <div class="grid-content grid-con-3">
                <i class="el-icon-s-goods grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{dashboard.today_number}}</div>
                  <div>今天产品总数</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <!-- <el-card shadow="hover" style="height:403px;">
                    <template #header>
                        <div class="clearfix">
                            <span>待办事项</span>
                            <el-button style="float: right; padding: 3px 0" type="text">添加</el-button>
                        </div>
                    </template>

                    <el-table :show-header="false" :data="todoList" style="width:100%;">
                        <el-table-column width="40">
                            <template #default="scope">
                                <el-checkbox v-model="scope.row.status"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template #default="scope">
                                <div
                                    class="todo-item"
                                    :class="{
                                        'todo-item-del': scope.row.status,
                                    }"
                                >{{ scope.row.title }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="60">
                            <template>
                                <i class="el-icon-edit"></i>
                                <i class="el-icon-delete"></i>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>  -->
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="bar"
            class="schart"
            canvasId="bar"
            :options="catOptions"
          ></schart>
        </el-card>
      </el-col> -->
      <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="line1"
            class="schart"
            canvasId="line1"
            :options="cityOptions"
          ></schart>
        </el-card>
      </el-col>
            <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="line2"
            class="schart"
            canvasId="line2"
            :options="portOptions"
          ></schart>
        </el-card>
      </el-col>

    </el-row>
    <!-- <el-row :gutter="20">

    </el-row> -->
  </div>
</template>

<script>
import Schart from "vue-schart";
import api from "@/api/index";
export default {
  name: "dashboard",
  data() {
    return {
      name: localStorage.getItem("ms_username"),
      dashboard: {
        today_number: 0,
        tomonth_number: 0,
        total_number: 0,
      },

      todoList: [
        {
          title: "今天要修复100个bug",
          status: false,
        },
        {
          title: "今天要修复100个bug",
          status: false,
        },
        {
          title: "今天要写100行代码加几个bug吧",
          status: false,
        },
        {
          title: "今天要修复100个bug",
          status: false,
        },
        {
          title: "今天要修复100个bug",
          status: true,
        },
        {
          title: "今天要写100行代码加几个bug吧",
          status: true,
        },
      ],
      data: [
        {
          name: "2018/09/04",
          value: 1083,
        },
        {
          name: "2018/09/05",
          value: 941,
        },
        {
          name: "2018/09/06",
          value: 1139,
        },
        {
          name: "2018/09/07",
          value: 816,
        },
        {
          name: "2018/09/08",
          value: 327,
        },
        {
          name: "2018/09/09",
          value: 228,
        },
        {
          name: "2018/09/10",
          value: 1065,
        },
      ],
      catOptions: {
        type: "pie",
        title: {
          text: "分类产品数量饼状图",
        },
        legend: {
          position: "left",
        },
        bgColor: "#fbfbfb",
        labels: ["T恤", "牛仔裤", "连衣裙", "毛衣", "七分裤", "短裙", "羽绒服"],
        datasets: [
          {
            data: [334, 278, 190, 235, 260, 200, 141],
          },
        ],
      },
      cityOptions: {
        type: "pie",
        title: {
          text: "城市产品数量饼状图",
        },
        legend: {
          position: "left",
        },
        bgColor: "#fbfbfb",
        labels: ["T恤", "牛仔裤", "连衣裙", "毛衣", "七分裤", "短裙", "羽绒服"],
        datasets: [
          {
            data: [334, 278, 190, 235, 260, 200, 141],
          },
        ],
      },
      portOptions: {
        type: "pie",
        title: {
          text: "港口产品数量饼状图",
        },
        legend: {
          position: "left",
        },
        bgColor: "#fbfbfb",
        labels: ["T恤", "牛仔裤", "连衣裙", "毛衣", "七分裤", "短裙", "羽绒服"],
        datasets: [
          {
            data: [334, 278, 190, 235, 260, 200, 141],
          },
        ],
      },
    };
  },
  components: {
    Schart,
  },
  computed: {
    role() {
      return this.name === "admin" ? "超级管理员" : "普通用户";
    },
  },
  mounted() {
    this.getDashboard();
  },

  methods: {
    getDashboard() {
      api
        .dashboard()
        .then((res) => {
          //   let label;
          //   let datasets;
          this.catOptions.labels = Object.keys(res.data.cat_product_number);
          this.catOptions.datasets[0].data = Object.values(
            res.data.cat_product_number
          );
          this.cityOptions.labels = Object.keys(res.data.city_product_number);
          this.cityOptions.datasets[0].data = Object.values(
            res.data.city_product_number
          );
          this.portOptions.labels = Object.keys(res.data.port_product_number);
          this.portOptions.datasets[0].data = Object.values(
            res.data.port_product_number
          );
          this.dashboard = res.data
          //   let catData = {};
          //   catData.label = "分类";
          //   catData.data = res.data.cat_product_number.this.options.datasets = [];
        })
        .catch(() => {});
    },
    changeDate() {
      const now = new Date().getTime();
      this.data.forEach((item, index) => {
        const date = new Date(now - (6 - index) * 86400000);
        item.name = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}`;
      });
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
